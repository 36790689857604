.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  background: rgba(1, 79, 112, 1);
  color: white;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  box-shadow: none;
  border-width: 0px;
  transition-duration: 300ms;
  text-align: center;
  height: fit-content;
  box-sizing: border-box;

}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  color: rgba(1, 79, 112, 1);
  background: transparent;
  transition-duration: 300ms;
}

.button--inverse {
  background: transparent;
  color: rgba(1, 79, 112, 1);
}

.button-rounded {
  border-radius: 35px;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: rgba(1, 79, 112, 1);
  transition-duration: 300ms;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;

  border-color: #f34343;
  transition-duration: 300ms;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  cursor: not-allowed;
  transition-duration: 300ms;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

.cerin-button:disabled {
  background-color: rgb(1 79 112 / 50%);
}
.cerin-button {
  border: 0;
  font-size: 12px;
  font-family: 'Poppins';
  background-color: rgba(1, 79, 112, 1);
  color: white;
}
.addProject-content {
  width: 67%;
  background-color: white;
  border-radius: 21px;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: 10rem;
}

.addProject-form {
  width: 100%;
  background-color: white;
  border-radius: 21px;
  min-height: 90%;
  height: fit-content;

  text-align: center;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
}
.project-type__select {
  width: -webkit-fill-available;
  position: relative;
  resize: none;
  font-size: 22px;
  font-family: 'League Spartan';
  border: 1px solid #707070;
  padding: 5px;
}

.file-input {
  position: relative;
}

.project-title {
  width: -webkit-fill-available;
  /* height: 2em; */

  position: relative;
  /* top: 8vh;
  margin-left: auto;
  margin-right: auto; */
  margin-bottom: 5%;

  resize: none;
  font-size: 30px;
  text-align: center;

  white-space: pre-wrap;
}

.project-overview__form {
  width: -webkit-fill-available;
  resize: none;
}

.project-overview__form-input {
  width: 100%;
  font-size: 20px;
}

.project-name {
  font-size: 25px;
  width: 25vw;
}

.project-desc {
  font-size: 20px;
  width: 25vw;
  height: 20rem;
  resize: none;
}

.project-thumbnail {
  position: relative;
  right: 20;
}

.project-preview {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;

}

/* .project-preview-form {
  height: 60vh;
  width: 60vw;
} */

.contact-list-form {
  /* margin-top: 10vh; */
  margin-left: auto;
  margin-right: auto;
}

.keyword-list-form {
  margin-left: auto;
  margin-right: auto;
}

/* .member-list-form {
  margin-top: 10vh;
  height: fit-content;
} */

.submit-button {
  margin-top: 10vh;
  margin-bottom: 5vh;
}

.page-separator {
  display: block;
  width: 100%;
  height: 1px;
  background: rgb(206, 206, 206);
  margin-top: 10%;
  margin-bottom: 10%;
}

.addproject-page1 {
  width: inherit;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.addProject-content textarea {
  font-family: 'League Spartan';
  width: -webkit-fill-available;
}
.addproject-page2 .project-box {
  display: flex;
  align-items: center;
}
.addProject-content h3, .addProject-content h1{
  font-family: 'Poppins';
  color: rgba(1, 79, 112, 1);
  font-weight: 900;
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 5px
} 
.project-box {
  border: 2px solid rgba(1, 79, 112, 1);
  border-radius: 1rem;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 50%);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

input#pdf {
  border: 1px solid rgb(94, 94, 94);
}

input#file-upload-button {
  background-color: #014F70;
  font-size: 13px;
}

input {
  font-family: 'Poppins';
}
.addprojectpage {
  gap: 1rem;
}

tr.MuiTableRow-root.MuiTableRow-head.css-1q1u3t4-MuiTableRow-root {
  border: 0;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
}

/* Add Project Nav */
.addproject-tabs-wrap {
  width: 100%;
  margin: 1rem;
}

.addproject-tabs {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
}

.tab-button {
  font-family: 'Poppins';
  background-color: white;
  width: 100%;
  border: 2px solid rgba(1, 79, 112, 1);
  border-radius: 2rem;
  padding: 5px;
  color: rgba(1, 79, 112, 1);
  font-size: 16px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
  transition: 300ms;
}

.tab-button.selected {
  color: white;
  background-color: rgba(1, 79, 112, 1);
  transition: 300ms;
}

.project-box.project-privacy {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.addproject-page5 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
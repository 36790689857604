.contact-list-content {
  width: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  max-height: 22rem;
}
.contact-list {
  margin-top: 10vh;
  height: 30rem;
  min-width: 25vw;
}

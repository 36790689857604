@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
.main-header {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(1, 79, 112, 0.9);
  padding: 1 1rem;
  transition-timing-function: ease-in-out;
  transition: background-color 0.4s, height 0.4s linear;
}
.header__top-of-page.landing-header {
  height: 5rem;
}


.header__top-of-page {
  height: 10rem;
  background-color: rgba(1, 79, 112, 1);
}
.main-header.header__top-of-page.landing-header {
  background-color: unset;
}
.header__scrolled-down {
  height: 5rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}

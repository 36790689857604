@import url("https://fonts.googleapis.com/css2?family=League+Spartan&display=swap");
.project-list-item {
  position: relative;
  margin-bottom: 2%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  display: flex;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "League Spartan", sans-serif;
  /* min-height: 410px; */
  height: -moz-fit-content;
  height: fit-content;
  max-height: 25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  overflow: hidden;
  background: white;

}
.project-list-item__keywords {
  display: flex;
  width: 90%;
  max-width: 285px;
  min-height: 35px;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
  flex-wrap: wrap;
  font-size: 18px;
  color: rgb(158, 158, 158);
}
.project-list-item__keyword {
  white-space: pre;
}

.project-list-item__image {
  height: 12rem;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-list-item__text-box {
  margin-left: 1rem;
  margin-right: 1rem;
}

/* .title-desc-box {
  position: absolute;
  right: 0;
  width: 11.3vw;
  float: right;
} */

/* @media only screen and (max-width: 740px) {
  .project-list-item__image {
    height: 100%;
    width: 100%;
    float: none;
  }

  .project-list-item {
    height: 227px;
    width: 345px;
  }
  .title-desc-box {
    position: absolute;
    right: 0;
    top: 0;
  }
} */

.project-list li a {
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 12rem;
}

@media (max-width: 768px) {

.project-list-item {
  margin: 0;
}

}
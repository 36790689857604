.main-navigation {
  width: 100%;
  position: fixed;
  z-index: 200;
  font-family: "Cabin", sans-serif;
}
.main-navigation:after {
  content: " ";
  display: block;
  clear: both;
}

.cerin-logo__image {
  max-height: 123px;
  
}
.main-navigation__menu-btn {
  float: right;
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 2rem;
  cursor: pointer;
  position: absolute;
  right: 10%;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: #54c3b3;
}

.main-navigation__title {
  display: flex;
  user-select: none;
  width: 50rem;
}
.cerin-header__logo {
  transition: transform 0.6s;
  position: absolute;
  top: 10px;
}
.header__top-of-page .cerin-header__logo {
  transform: scale(01);
  transform-origin: top left;
  width: 12%;
}
.header__scrolled-down .cerin-header__logo {
  transform-origin: top left;
  transform: scale(0.5);
}
.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}
.user-welcome {
  position: absolute;
  top: 5%;
  margin-right: 2rem;
  font-size: 16px;
  color: white;
}
header.main-header.header__top-of-page.false
  .main-navigation__title
  a.cerin-header__logo {
  margin-top: 5px;
}
header.main-header.header__top-of-page.false .navigation {
  margin-top: 0rem;
}


::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #54c3b3;
    background: linear-gradient(180deg, rgba(84,195,179,1) 0%, rgba(54,128,117,1) 100%);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(1, 79, 112, 1);
  border-radius: 5px;
  border: 2px solid #54c3b3;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: flex;
    justify-content: flex-end;
  }
  .main-navigation__title {
    margin-left: 1%;
    user-select: none;
  }
}
@media (max-width: 768px) {
  .main-navigation {
    z-index: 1;
  }
  a.cerin-header__logo {
    margin: 5px;
  }
}

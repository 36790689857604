.nav-links {
  list-style: none;
  height: 3rem;
  display: flex;
  user-select: none;
  padding-inline-start: 0px;
  margin-right:2rem;
}


.nav-links li,
.nav-links button {
  font-size: 28px;
  width:max-content;
}

.nav-links button {
  text-align: left;
  width: 100%;
  border: none;
}

.nav-links a,
.nav-links button {

  display: block;
  color: white;
  text-decoration: none;
  padding-left: 1rem;
  padding-right: 1.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-left: solid 1px #bbbbbb;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;

}
.nav-links .first {

  border-left: transparent;
}

.nav-links a:hover,
.nav-links button:hover {
  background: #ffffff13;
  color: white;
  transition-duration: 300ms;
}

.nav-links a:active,
.nav-links button:active {
  background: #ffffff13;
  color: white;
  transition-duration: 300ms;
}
.navigation {
  margin-top: 1rem;
}
.navigation .dropdown-list a:hover, .dropdown-list button:hover {
  color: rgb(133 198 225);
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links a {
    color: white;
    transition-duration: 300ms;

    text-decoration: none;
  }
}
@media only screen and (max-width: 740px) {
  .nav-links .first {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .nav-links {
    display: list-item;
  }
  ul.nav-links li a {
    color: rgba(1, 79, 112, 1);
}
}

.contact-list-form{
    width:max-content;
    background-color:white;

}

.contact-list-input{
    width:fit-content;
    display:flex;
}


.contact-list-input input{
    margin-right:1vw;
}


.test-content {
  width: 100%;
  background-color: rgb(227, 227, 255);
  margin-top: 10rem;
}
#form-file-upload {
  /* height: 16rem;  */
  /* width: 28rem; */
  max-width: 100%;
  text-align: center;
  position: relative;
  border: 3px dashed rgba(1, 79, 112, 1);
}

.attachments__input {
  display: none;
}

.attachments__input-label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
}
attachments__input-label.drag-active {
  background-color: #ffffff;
}

.upload-button:hover {
  text-decoration-line: underline;
}
#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.download-file-modal__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

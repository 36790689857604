.keyword-list-form{
    width:max-content;
    background-color:white;
    min-width:15vw;

}
.keyword-list-input{
    width:fit-content;
    display:flex;
}

.keyword-list{
    overflow-y: auto;
    overflow-x: auto;
    width:100%;
    height:30vh;
}
.keyword-list-input input{
    margin-right:1vw;
}


.auth {
  height: 63.1vh;
  width: 32%;
  min-width: 340px;
  top: 10rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  z-index: 1;
}
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap");

.login-box {
  background-color: white;
  border-radius: 21px;
  width: 100%;
  height: 90%;
}
.login-box__title {
  text-align: center;
  position: relative;
  top: 5%;
  font-size: 60px;
  font-family: "League Spartan", sans-serif;
  color: white;
  background-color: rgba(1, 79, 112, 1);
  padding: 1rem;
  border-radius: 10rem;
}
.login-box span {
  display: block;
  width: 80%;
  height: 1px;
  background: #707070;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7%;
  visibility: collapse;
}
input:-webkit-autofill::first-line {
  font-size: 16px;
}

.login-box__form h2 {
  font-size: 25px;
  position: relative;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
}
.login-box-input {
  font-size: 28px;
  position: relative;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 78%;
  height: 35px;
  border-radius: 21px;
  border-width: 0.5px;
  padding-left: 2%;
}

.login-box-input label {
  left: 10%;
}

.login-button {
  margin-top: 4%;
  width: fit-content;
  position: relative;
  float: right;
  right: 6%;
}

.input-label {
  font-size: 28px;
  position: relative;
  margin-bottom: 2%;
  left: 8%;
  padding-left: 2%;

  font-family: "League Spartan", sans-serif;
  color: rgba(1, 79, 112, 1);
}
.login-error-message{
  text-align: right;
  margin-right:4rem;
  color:red;
}
.form-control--invalid p {
  left: 10%;
}
.login-box .login-box-input {
  border: 1px solid rgba(1, 79, 112, 1);
  font-size: 20px;
  font-family: 'Cabin';
}
.modal {
  z-index: 100;
  position: fixed;
  top: 20vh;
  width: fit-content;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  text-align: right;
}

.modal__header {
  padding: 1rem 0.5rem;
  background: #40947b;
  color: white;
}
.modal-button {
  margin-left:1rem;
  margin-right:1rem;
}

.modal__header h2 {
  margin: 0.5rem;
  width: fit-content;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  justify-content: center;
}

.modal-box {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 10;
}

.long {
  top: 10% !important;
}

@media (min-width: 768px) {
  .modal {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 15%;
  }

  .content-empty {
    padding: unset;
  }

  .small {
    text-align: center;
    width: 15rem;
  }

  .error {
    text-align: center;
    width: fit-content;
  }
}

.modal__footer Button {
  margin-left: 1rem;
  margin-right: 1rem;
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.attachments-box-item {
    /* width: 260.5px; */
    margin-left: 1px;
    margin-right: 1px;
    border: solid 3px #555555;
    border-radius: 2rem;
    height: 18rem;
    break-inside: avoid-column;
    /* float: left; */
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 210px;
  width: 240px;
  border: solid 1px #555555;
  border-color: rgb(141, 141, 141);
  background-color: #fafafa;
  /* margin: auto; */
  margin-top: 0;
  border-radius: 1rem;
}
.file-image {
  height: auto;
  width: auto;
  max-height: 210px;
  max-width: 240px;
}
.download-link {
  display: flex;
  text-align: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}
.download-icon {
  width: 15px;
  height: 15px;
  margin-left: 10px;
}

.attachment-file-name {
  font-size: 13px;
  width: 100%;
  display: flex;
  height: 40px;
  position: absolute;
  bottom: 0;
}
.attachment-remove-button {
  position: absolute;
  margin-left: 0 !important;
  margin-right: 0 !important;
  right: 0;
  z-index: 0;
}

.attachment__download-link {
  color: #2d7560;
  text-decoration: underline;
  cursor: pointer;
}
@media only screen and (max-width: 740px) {
  .attachments-box-item {
    width: 100%;
    height: 70%;
  }
  .file-image {
    width: 100%;
    height: 90%;
  }
}

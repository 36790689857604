.etc-projects__content {
  display: flex;
  flex-direction: column;
  margin-top: 11em;
  margin-bottom: 2em;
  align-items: center;
  font-family: "Cabin", sans-serif;
  padding: 1rem;
  height: -webkit-fill-available;
}

.etc-projects__content ul {
}
.project-status__icon {
  max-height: 4rem;
  max-width: 4rem;
  margin: 5px;
}
.site-user__description {
  cursor: text;
  text-align: center;
  align-self: flex-start;
  margin: auto;
}

.project-card__has-report {
  cursor: pointer;
}

.etc-projects__content table {
  max-width: 85vw;
  margin: 1rem;
  text-align: center;
  border: 0;
  box-shadow: 3px 0 7px 0px rgb(0 0 0 / 50%);
}
.site-user__logo img {
  max-height: 10em;
  max-width: 100%;
}
.etc-projects__content thead {
  background-color: rgba(1, 79, 112, 1);
  color: white;
  font-family: "League Spartan", sans-serif;
  font-size: 20px;
}

.etc-projects__content thead th {
  padding: 1rem;
}

.etc-projects__content tr td {
  padding: 1rem;
}

.etc-projects__content tr td:first-of-type {
  font-weight: bold;
}

.etc-projects__content table tbody {
  font-size: 25px;
}

.etc-projects__content table tbody tr:nth-of-type(even) {
  background-color: #e5e5e5;
}
.site-user__location, .site-user__framework {
  font-size: 18px;
  /* cursor: text;*/
}
.modal.select-company-modal {
  width: 100%;
  height: 100%;
  text-align: center;
  background-image: url(/src/assets/images/backgrounds/natural-gas-863226_1280.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal.select-company-modal form .modal__content.select-company-modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10rem;
  gap: 10rem;
  margin-bottom: 8rem;
}

.modal.select-company-modal .modal__header {
  display: none;
}

.modal.select-company-modal
  form
  .modal__content.select-company-modal__content
  button {
  width: 15vw;
  font-size: 40px;
  font-family: "Poppins";
  border: 2px solid white;
  font-weight: 700;
  box-shadow: 3px 2px 6px hwb(0deg 0% 100% / 75%);
}

.modal.select-company-modal
  form
  .modal__content.select-company-modal__content
  button:hover {
  background-color: white;
}

.project-card {
    /* border: 10px solid rgba(1, 79, 112, 1); */
    border-radius: 1rem;
    box-shadow: 0 3px 6px 2px rgb(0 16 23 / 75%);
    background: linear-gradient( 0deg, rgba(227, 227, 227, 1) 0%, rgba(255, 255, 255, 1) 50% );
    margin: 1rem;
    width: 24rem;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: 1300ms;
    justify-content: center;
    flex-direction: column;
}

.project-card-top {
  min-width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  /* background-color: white; */
  justify-content: center;
  align-content: center;

}
.project-card-bottom {
  /* display: none; */
  background-image: url(/src/assets/images/backgrounds/report-card-background-back.png);
  width: 100%;
  height: 100%;
  transition: 0.4s;
}
.project-card-bottom-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  /* grid-template-rows: 80% 20%; */
  align-items: center;
  justify-items: center;
  flex-direction: column;
  justify-content: flex-end;
  /* transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); */
}

.project-card__container:hover .project-card {
  /* animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both; */
  /* transform: rotateY(180deg); */

  transition: 0.4s;
}

.project-card__container:hover > .project-card .project-card-top {
  /* display: none; */
  /* animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both; */
}
.project-card__container:hover > .project-card .project-card-bottom {
  /* display: grid; */
  /* animation: flip-vertical-left 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both; */
}
.site-user__status {
  text-align: end;
}

.project-card-top .site-user__logo {
  justify-self: center;
  width: 100%;
  text-align: center;
}

.project-cards-wrap {
  display: flex;
  justify-content: center;
  height: auto;
  flex-wrap: wrap;
}

.project-card__container.CANEric .site-user__location {
  justify-self: flex-start;
}

.project-card__container.CANEric .site-user__framework {
  justify-self: flex-end;
}

.project-card__container.CANEric .site-user-bottom-info{
  grid-template-columns: 1fr 1fr;

}

.site-user-bottom-info {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
}
.project-card__container.ETC .site-user__framework {
  display: none;
}
.site-user-backbottom {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
}
hr.project-card-split {
  border-top: 2px solid rgb(141, 141, 141);
  width: -webkit-fill-available;
  border-radius: 1rem;
  box-shadow: 0px 1px 6px grey;
  background: 0;
}

.project-card__container {
  height: 25em;
  margin-bottom: 3rem;
}

.card-bottom-info {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  justify-content: space-between;
}

.location-framework {
  display: flex;
  gap: 5px;
  align-items: center;
}
/* .project-card__container.container-etc {
  height: 15%;
} */

@-webkit-keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}
@keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}

/*.etc-projects__content tr td:first-of-type, .etc-projects__content thead th:first-of-type {
    box-shadow: 6px 0px 5px 0px rgb(0 0 0 / 20%);
}
*/

.view-pdf {
  position: relative;
  height: fit-content;
  border: black 1px solid;
  padding-top: 3vh;
  padding-bottom: 3vh;
  margin-left: auto;
  margin-right: auto;
}

.pdf-document {
  max-width: 60vw;
  max-height: 100vh;
  min-width: 814px;
  min-height: 1028px;
}

.pageNum {
}

.pageNum-top {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.pageNum-bottom {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.react-pdf__Page {
  position: absolute;
  max-height:100vh;
}

.react-pdf__Page__canvas {
  position: relative;
}

.react-pdf__Page__textContent {
  position: absolute;
}

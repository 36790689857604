.view-project__project {
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid rgb(141, 141, 141); */
}
.edit-button {
  margin-right: 5%;
  width: -moz-fit-content;
  width: 7rem;
  float: right;
  margin-top: -5.5rem;
}
.view-project__author {
  float: right;
  margin-right: 5%;
  width: fit-content;
  opacity: 80%;
}
/* .view-project__background {
  background-size: cover;
  height: 100%;
  width: 100vw;
  background-repeat: no-repeat;
  position: fixed;
  background-image: url("../../assets//images//backgrounds/_S3A0959.jpg");
  z-index: -1;
} */

.submit-cancel-buttons {
  text-align: right;
  margin-bottom: 1rem;
  margin-top: -5.5rem;
}
.submit-cancel-buttons Button, .edit-button button {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 7rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.view-project-content {
  width: -webkit-fill-available;
  background-color: white;
  border-radius: 21px;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 61.1vh;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10vh;
  margin-top: 10rem;
  font-family: "Cabin", sans-serif;
}
.project-edit-form {
  text-align: center;
}

.view-project-content h1 {
  text-align: center;
}
.view-project-tabs {
  width: 100%;
  /* height: 1.5em; */
  font-size: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  /* margin-bottom: -2rem; */
  display: flex;
  grid-gap: 5px;
  grid-gap: 5px;
  gap: 5px;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.view-project-tabs button {
  background: 0;
  border: none;
  font-size: 30px;
  padding-top: 8px;
  font-family: "Cabin", sans-serif;
  color: white;
  padding-bottom: 5px;
}

.project-page-tab {
  /* width: 5em; */
  height: 1.5em;
  /* border-top-left-radius: 21px; */
  /* border-top-right-radius: 21px; */
  text-align: center;
  position: relative;
  /* bottom: 1.5em; */
  float: left;
}

.members-page-tab {
  /* width: 6em; */
  height: 1.5em;
  /* bottom: 1.5em; */
  /* border-top-left-radius: 21px; */
  /* border-top-right-radius: 21px; */
  text-align: center;
  position: relative;
  float: right;
}
.view-tab.tab-activated {
  background-color: rgba(1, 79, 112, 1);
  color: white;
  transition: 300ms;
}
.view-tab button {
  color: rgba(1, 79, 112, 1);
  background-color: white;

}

.view-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6em;
  border: 2px solid rgba(1, 79, 112, 1);
  color: rgba(1, 79, 112, 1);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  transition: 300ms;
}
.view-project__title {
  width: 80%;
  height: 2em;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 5%; */
  resize: none;
  font-size: 30px;
  text-align: center;
  white-space: pre-wrap;
  font-family: 'Poppins';
  color: rgba(1, 79, 112, 1);
}
.view-project__overview {
  position: relative;
  width: 90%;
  resize: none;
  font-size: 20px;
  margin-bottom: 10%;
  left: 4.5%;
  top: 15%;
  word-break: break-all;
  white-space: pre-wrap;
}

.view-project__pdf {
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.project-overview {
  position: relative;
  top: 8vh;
  width: 90%;
  resize: none;
  font-size: 20px;
  margin-bottom: 30%;

  margin-left: auto;
  margin-right: auto;
}
.project-name {
  font-size: 25px;
  width: 25vw;
}
.project-desc {
  font-size: 20px;
  width: 25vw;
  height: 20rem;
  resize: none;
}
.project-preview-form {
  height: 60vh;
  width: 60vw;
  margin-bottom: 10vh;
}
/* .contact-list-form {
  margin-top: 10vh;
  margin-left: auto;
  margin-right: auto;
} */
.keyword-list-form {
  margin-left: auto;
  margin-right: auto;
}
/* .member-list-form {
  margin-top: 10vh;
  height: fit-content;
} */
.view-tab-line {
  width: -webkit-fill-available;
  border: 1.5px solid rgba(1, 79, 112, 1);
  margin-top: -7px;
}

.vieweditproject-tabs-wrap {
  width: -webkit-fill-available;
  margin: 1rem;
  display: flex;
  justify-content: center;
}

.vieweditproject-tabs {
  display: flex;
  justify-content: space-evenly;
  grid-gap: 1rem;
  gap: 1rem;
  width: 50%;
}

.tab-button {
  font-family: 'Poppins';
  background-color: white;
  width: 100%;
  border: 2px solid rgba(1, 79, 112, 1);
  border-radius: 2rem;
  padding: 5px;
  color: rgba(1, 79, 112, 1);
  font-size: 16px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
  transition: 300ms;
  text-align: center;
}
.viewpage-wrap {
  display: flex;
  justify-content: center;
}

.viewpage-wrap h2, .viewpage-wrap h3, .viewpage-wrap h1, .view-project__project h2, .view-project__project h1, .view-project__project h3{
  font-family: 'Poppins';
  color: rgba(1, 79, 112, 1);
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.project-overview {
  font-family: 'League Spartan';
  width: -webkit-fill-available;
}
.project-box button {
  border: 0;
  background-color: rgba(1, 79, 112, 1);
  color: white;
  font-family: 'Cabin';
}
.view-project__project .addprojectpage {
  width: -webkit-fill-available;
  display: flex;
  justify-content: center;
}
.project-box.projectview-overview {
  width: 25%;
}
h1.attachments-box-label {
  text-align: center;
  width: -webkit-fill-available;
}
.project-box.view-members {
  display: flex;
  align-items: center;
  width: 50%;
}

.view-project-content .addprojectpage.addproject-page4 {
  width: -webkit-fill-available;
}

.project-box.projectview-view {
  width: 80%;
}

.view-project-content .addprojectpage.addproject-page4 .project-preview-form__image {
  float: none;
  margin: 0;
  align-self: center;
}

.view-project-content .addprojectpage.addproject-page4 .project-box:first-child {
  display: flex;
  align-items: flex-start;
}
.view-project-member-wrap {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .view-project-content {
    width: 100%;
  }
}

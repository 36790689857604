.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-size: 28px;
  position: relative;
  margin-top: 5%;
  font-weight: bold;
}

.rounded {
  border-radius: 21px;
}
.form-control input,
.form-control textarea {
  position: relative;
  border-width: 0.5px;
}

.form-control .no-focus input:focus {
  background: none;
}

.form-control input:focus,
.form-control .auth textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
  position: relative;
}

.form-control--invalid input,
.form-control--invalid .auth textarea {
  border-color: red;
  background: #ffd1d1;
  position: relative;
}

.errorText{
  color:red;
  margin-left:4rem;
  margin-top:-2rem;
  position:absolute;
}
.pdf-preview__empty {
  align-items: center;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  display: flex;
  font-size: 2rem;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  min-width: 41vw;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
}
.pdf-preview__picked {
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 100%;
}
.pdf-upload-box {
  text-align: center;
  height: fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.pdf-preview {
  height: fit-content;
}

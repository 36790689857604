.image-upload .center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.image-upload__preview {
    /* height: 25vh; */
    width: 15vw;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.image-upload__preview img {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  object-fit: cover;
}

.image-upload__button{
  position:absolute;
  
}


.thumbnail{
  margin:0;
}

.image-upload {
  width: 100%;
  height: 100%;
}
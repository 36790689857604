.site-users__content {
  margin-top: 10rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
}

.site-users__title {
  font-size: 60px;
  font-family: "League Spartan", sans-serif;
  color: rgba(1, 79, 112, 1);
  margin-top: 3rem;
  font-weight: 700;
}

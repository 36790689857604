main {
  min-width: 99vw;
  width: 100%;
  height: fit-content;
  flex: 1 0 auto;
  display:flex;
  justify-content: center;
}
html{
  overflow:overlay;
}

@media only screen and (max-width: 740px) {
  main {
   top:10rem;
  }
}

.site-user-status__toggle-button {
  /* border: 1px solid black; */
  border-radius: 1000px;
  padding: 5px;
  width: 50%;
  color: white;
  text-align: center;
  cursor: pointer;
}
.site-user__search-bar-content {
  width: 50vw;
  border: 6px solid rgba(1, 79, 112, 1);
  padding: 1rem;
  box-shadow: 0px 3px 6px hsl(0deg 0% 0% / 50%);
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.site-user__search-bar-header {
  font-size: 20px;
  color: rgba(1, 79, 112, 1);
}

.site-user-main-filter-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
}
.site-user-sorters {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}

.site-user-filter-search div {
  display: flex;
}

input#searchCriteria,
select#searchLocation,
select#searchSiteUser,
select#searchFramework,
select#searchProjectGroup {
  border: 1px solid #555555;
  font-family: "Cabin";
  font-size: 18px;
}
select#searchLocation,
select#searchSiteUser,
select#searchFramework,
select#searchProjectGroup {
  padding: 5px;
}
.site-user__search-bar-header h3 {
  margin-bottom: 0;
}
.site-user__search-bar-content h4 {
  font-size: 18px;
  color: rgba(1, 79, 112, 1);
  margin-bottom: 8px;
}

input#searchCriteria {
  width: 25vw;
}

.site-user-status__toggles {
  width: 25%;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 5px;
  align-items: center;
}
.site-user-filter-dropdown {
  display: flex;
  gap: 2rem;
}
.site-user-status__toggle-button {
  background-color: white;
  border: 1px solid black;
  color: black;
}

.site-user-status__toggle-button.site-user-status__all-toggled {
  background-color: #555555;
  color: white;
  border: 1px solid #555555;
}

.site-user-status__toggle-button.site-user-status__ongoing-toggled {
  background-color: rgba(1, 79, 112, 1);
  color: white;
  border: 1px solid rgba(1, 79, 112, 1);
}

.site-user-status__toggle-button.site-user-status__completed-toggled {
  background-color: #186935;
  color: white;
  border: 1px solid #186935;
}
.site-filter-ETC {
  display: none;
}

.site-filter-right-CANEric {
  display: none;
}

.site-user-project-wrapCANEric {
  display: flex;
  gap: 2rem;
  width: -webkit-fill-available;
}

.site-user-project-wrapCANEric .site-user-filter-search {
  width: 50%;
}

.site-user-project-wrapCANEric input#searchCriteria {
  width: -webkit-fill-available;
}

.site-filter-group-ETC {
    display: none;
}

.site-filter-right-ETC select#searchSiteUser {
  width: 14vw;
}
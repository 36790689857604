.card {
  position: relative;

  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  overflow: hidden;
  background: white;
}
@media only screen and (max-width: 740px) {
  /* .card {
    height: 227px;
    width: 99%;
  } */
}

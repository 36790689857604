.dropdown-list {
  list-style: none;
  position: relative;
  padding-inline-start: 0;
  margin: 0;
  z-index: 1000;
  background-color: rgb(255 255 255);
  border-left: 1px solid rgba(1, 79, 112, 1);
  box-shadow: 3px 3px 6px 0px rgb(0 0 0 / 50%);
}

.dropdown-list li {

  width: 10.5rem;
  text-align: center;
  margin-top: -3px;
}
.dropdown-list li a, .dropdown-list li button {
  color: rgba(1, 79, 112, 1);
}
.dropdown-header {
  position: relative;
  top: 0;
}

.dropdown-header button,
.dropdown-list button {
  background-color:unset;
  width: 10.5rem;

  font-size: 25px;
  border: 1px solid transparent;
  border-left-color: #bbbbbb;

  color: white;

  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.45rem;
}
.dropdown-list button:focus,
.dropdown-header button:focus {
  outline: none;
}
.dropdown-list button:hover,
.dropdown-header button:hover {
  background: #54c3b3;
  color: white;
  transition-duration: 300ms;
}

.dropdown-list button:active,
.dropdown-header button:active {
  background: #54c3b3;
  color: white;
  transition-duration: 300ms;
}

.arrow {
  border: solid white;

  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 6px;
  margin-left: 3px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

a {
  text-align: left;
}

.project-card__title {
  word-wrap: break-word;
  font-size: 24px;
  height: fit-content;
  color: rgba(1, 79, 112, 1);
  margin-top: 1rem;
}
.project-card__box {
  width: -webkit-fill-available;
  height: 90px;

  /* height: 170px; */
}
.project-card__date {
  margin-top: 0.5rem;
  font-size: 18px;
}
.project-card__author {
  margin-top: 0.5rem;
  font-size: 18px;
}
.project-card__institution-company {
  font-size: 18px;
}
.project-card__institution-company b {
  color: rgba(1, 79, 112, 1);
}

.project-card__title-long{
  font-size:18px;
}
.project-card__title-very-long{
  font-size:15px;
}
/*



.description {
  margin-top: 2vh;
  font-size: 15px;
  word-wrap: break-word;
  height: 7.5rem;
  overflow: hidden;
  width: 11.2vw;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.date {
  margin-bottom: 5px;
  margin-right: 2px;
  font-size: 15px;
  word-wrap: break-word;
  width: 210px;
  position: absolute;
  text-align: right;
  bottom: 0;
  opacity: 0.6;
  right: 10px;
  color: black;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1920px) {
  .title {
    font-size: 23px;
  }

  .description {
    font-size: 14px;
  }

  .date {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    background: none;
    color: white;
    height: 227px;
    width: 215px;
  }

  .title {
    width: 205px;
  }

  .description {
    width: 205px;
  }

  .title-desc-box__mobile-background {
    height: 227px;
    width: 215px;
    background-color: black;
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 0;
  }
} */

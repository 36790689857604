.footer-content {
  background-color: black;
  width: 100%;
  height: 70px;
  z-index: 100;

}
.footer-text {
  position:relative;
  color: white;
  text-align: center;
}
@media only screen and (max-width: 740px) {
  .footer-content{
    
  }
}

@media only screen and (max-width: 500px) {

p.footer-text {
  top: -10%;
}

}

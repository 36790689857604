.addproject-page4 > .project-preview-form {
  position: relative;
  /* margin-top: 10vh; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
}
.project-preview-form__text {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  float: left;
  position: relative;
  /* margin-left: 5vw; */
  /* margin-right: 5vw; */
  margin-bottom: 1vh;
}
.project-preview-form__image {
  width: fit-content;
  height: fit-content;
  position: relative;
  float: right;
  margin-right: 5vw;
  margin-bottom: 5vh;
}
.project-date {
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: 3rem;
}
.project-date__input {
  font-size: 18px;
}

.addproject-page4 > .project-preview-form .project-box:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
}

.addproject-page4 > .project-preview-form .project-box:last-child {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
}

.addproject-page4 > .project-preview-form > .project-preview-form {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 2;
}

.project-preview-form .project-box:nth-child(2) {
  width: -webkit-fill-available;
  display: flex;
  align-items: flex-start;
}

input#projectName {
  font-size: 18px;
}
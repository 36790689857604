.site-user {
  height: 10rem;
  width: 15rem;
  margin: 1rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  box-shadow: rgb(221, 221, 221) 3px 1px 1px;
  border: 2px solid rgba(1, 79, 112, 1);
}
.report-list-modal__content {
  text-align: center;
}
.site-user__logo {
  max-width: 15rem;
  max-height: 10rem;
}
.site-user__name {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.site-user__name-long {
  font-size: 30px;
}

.add-members__button{
    float:right;
}

.view-current-members-list{
    width:50vw;
    min-width:340px;
    margin-left:auto;
    margin-right:auto;
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  width:70vw;
  min-width:30%;
}

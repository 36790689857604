.about-us__content {
  background-color: white;
  height: fit-content;
  width: 67%;
  border-radius: 21px;
  display: flex;
  padding-bottom: 5rem;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 10rem;
  align-items: center;
}
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap");
.about-us__title {
  font-family: "League Spartan", arial, helvetica, sans-serif;
  font-size: 60px;
  color: rgba(1, 79, 112, 1);
  margin-top: 3rem;
  font-weight: 700;
  margin-bottom: -6.7rem;
}
.institutions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.institution__box {
  display: flex;
  flex-direction: column;
  display: flex;
  margin-top: 5rem;
  margin-right: 1rem;
  margin-left: 1rem;
  width: 30rem;
  overflow: hidden;
  line-height: 1.7rem;
  font-size: 18px;
  color: rgb(94, 94, 94);
}
.institution__content {
  height: 15rem;
  overflow: hidden;
}
.about-us__show-more-less__button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: rgba(1, 79, 112, 1);
  cursor: pointer;
}
.institution__content__show-more {
  height: fit-content;
}
.NGIF__content.institution__content__show-more {
  height: fit-content;
}
.institution__logo-box {
  width: 30rem;
  min-height: 10rem;
  display: flex;
  align-items: center;
}
.NGIF__logo-box {
  align-items: flex-start;
  margin-top: 2.75rem;
  margin-left: 2rem;
}
.NGIF__box {
  flex-direction: row;
  width: fit-content;
}
.NGIF__content {
  margin-top: 1rem;
  height: 22rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
}
.institution__logo.NGIF__logo {
  max-width: 21rem;
}

.institution__logo {
  margin-bottom: 1rem;
  max-width: 30rem;
}
/* .link__align-left {
  text-align: left;
  margin: 0 0 0 1rem;
}
.contactInfo__align-left {
  text-align: left;
  width: fit-content;
}
.contact__align-left {
  flex-direction: column;
} */

.institution__contact {
  margin-top: 2rem;
  width: 100%;
  display: flex;
}

.institution__contactInfo {
  text-align: left;
  width: min-content;
  flex: 50%;
  display: inline-block;
  white-space: pre-line;
}

.institution__link {
  flex: 50%;
  width: 300px;
  text-align: right;
  margin-right: 1rem;
}
.ngif__logo-box {
  height: 20rem;
}

.about-us__learn-more__button {
  width: 10rem;
  font-family: 'Poppins', sans-serif;
}
/* .institution__logo-box {
  width: 470px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.ngif {
  width: 810px;
  margin-left: -15px;
}
.ngif .institution__learn-more-box {
  width: 300px;
}
.ngif-right {
  margin-left: 10px;
  width: 500px;
  height: 397px;
  position: relative;
}
.etc-photo {
  height: 174px;
  width: 232px;
}

.top-left {
  left: 10px;
  top: 10px;
  position: absolute;
}

.top-right {
  top: 10px;
  right: 10px;
  position: absolute;
}

.bottom-left {
  bottom: 10px;
  left: 10px;
  position: absolute;
}

.bottom-right {
  bottom: 10px;
  right: 10px;
  position: absolute;
}

.ngif-left .institution__logo-box {
  height: 300px;
  width: fit-content;
}

.institution__description-box {
  white-space: pre-line;
  width: 470px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.ngif-desc {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.desc1 {
  margin-right: 1rem;
}
.desc2 {
  margin-left: 1rem;
}
.institution__logo {
  max-height: 100%;
  max-width: 100%;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-self: center;
}



.institution__description {
  height: fit-content;
}
.institution__learn-more-box {
  height: 25%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.institution__name {
  height: 10%;
}

.institutions {
  width: fit-content;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.ngif-box {
  border: 1px solid #b9b9b9; 
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5rem;
  flex-direction: column;
}

.ngif__logo-box {
  width: 600px;
  height: max-content;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
}
.ngif__description-box {
  white-space: pre-line;
  width: 1100px;
  height: fit-content;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  flex-direction: row;
}
.ngif__desc1 {
  width: 530px;
  float: left;
  margin-right: 1rem;
}
.ngif__desc2 {
  width: 530px;
  float: right;
  margin-left: 1rem;
}

.ngif__logo {
  max-height: 100%;
  max-width: 100%;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-self: center;
}
.ngif__contact {
  text-align: left;
  height: 20%;
  margin-top: 2rem;
}
.ngif__description {
  height: fit-content;
}
.ngif__learn-more-box {
  height: 25%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ngif__name {
  height: 10%;
}
.carousels {
  text-align: center;
  margin-top: 6rem;
  width: 1100px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  display: flex;
  margin-bottom: 5rem;
}
.lab-box {
  width: 400px;
  margin-right: 6rem;
}
.field-box {
  width: 400px;
  margin-left: 6rem;
}
.carousel-image {
  height: 300px;
  max-width: 400px;
  width: fit-content;
}

.field-carousel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.third {
  height: fit-content;
}

.etc-contact {
  margin-right: 1%;
  width: 98%;
  height: fit-content;
  text-align: right;
}
.etc-contact p {
  margin-bottom: 5px;
}

.institution-modal__content {
  text-align: left;
}
.institution-modal__footer {
  display: flex;
  justify-content: center;
} */

@media only screen and (max-width: 740px) {

.about-us__title {
  width: 100%;
  text-align: center;
}
.institutions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  align-content: center;
}
.institution__box.NGIF__box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.institution__logo-box.NGIF__logo-box {
  margin: 0;
  justify-content: center;
}

}

@media only screen and (max-width: 500px) {

.institution__box {
  padding: 1rem;
  margin: 0;
}
.institutions {
  margin-top: 5em;
}

.institution__text {
  padding: 1rem;
}

.institution__description-box.ngif-desc {
  padding: 5rem;
  padding-top: 0;
}
}
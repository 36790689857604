.attachments-box {
  /* border: 1px solid #589490; */
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-height: 650px;
  max-width: 1070px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.box-list {
  padding: 0;
  justify-content: center;

  list-style-type: none;

  overflow-y: auto;
  overflow-x: hidden;
}
.attachments-box-label {
  text-align: center;
}

@media only screen and (max-width: 740px) {
  .attachments-box {
    width: 90%;
  }
}
